<template>
	<div class="main-content">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Create new carousel</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<router-link
				to="/games&campaigns/carousel"
				class="btn btn-light mb-4 btn-sm"
				>
				Go back
			</router-link>

			<div>
				<b-breadcrumb :items="items"></b-breadcrumb>
			</div>
			<div class="row">
				<div class="col-12 col-xl-6">
					<b-card>
						<form @submit.prevent="handleCarousels">
							<div class="form-group">
								<label style="color: #444854" class="form-label">Title</label>
								<input
									placeholder="Enter title"
									v-model="form.title"
									required
									type="text"
									class="form-control"
									/>
							</div>

							<p class="pb-2 mb-2 text-muted">
								You can add multiple carousel at a time
							</p>
							<b-card>
								<div
									class="d-flex justify-content-center align-items-center flex-column"
									>
									<img
										@click="handleUpload"
										src="@/assets/img/uploadIcon.svg"
										alt=""
										class="mb-4"
										style="cursor: pointer"
										/>

									<p class="pb-0 mb-0 text-muted" style="font-size: 13px">
										<span
											class="uploadText"
											@click="handleUpload"
											style="color: #4848ed; font-weight: bold"
											>
											Click to upload</span
											>
										or drag and drop
									</p>

									<p class="pt-0 mt-0 text-muted" style="font-size: 13px">
										SVG, PNG, JPG or GIF (max. 2480 x 1146px)
									</p>
									<input
										type="file"
										accept="image/*"
										ref="uploadRef"
										style="display: none"
										multiple
										@change="onFileSelected"
										/>
								</div>
							</b-card>

							<b-alert
								:show="show_size_warning_alert"
								fade
								dismissible
								variant="danger"
								>
								<small>
									Image does not meet the size requirements, upload a new
									image</small
									>
							</b-alert>

							<div class="d-flex align-items-center pt-0 mt-0 mb-5">
								<img src="@/assets/img/info_circle.svg" alt="uplodIcon" />
								<small class="text-muted text-secondary font-weight-light pl-3"
									>Images to be uploaded must be Rectangular (Vertical)
								</small>
							</div>

							<div class="form-group">
								<label style="color: #444854" class="form-label"
									>Select dynamic Urls</label
									>

								<div v-if="dynamic_urls.length">
									<b-row>
										<b-card-text
											class="grid spinnerParent"
											style="color: #09090f"
											v-for="(eachDynamicUrl, index) in dynamic_urls"
											:key="index"
											>
											<b-col>
												<input
													@change="handleSelectedDynamicUrl(eachDynamicUrl)"
													class="g-col-4"
													:id="eachDynamicUrl"
													type="checkbox"
													/>
												{{ eachDynamicUrl.toLowerCase().replace('_', ' ') }}
											</b-col>
										</b-card-text>
									</b-row>
								</div>
							</div>

							<div class="form-group">
								<label style="color: #444854" class="form-label"
									>End date</label
									>
								<v-datepicker
									style="width: 100%"
									v-model="form.end_date"
									:default-value="new Date()"
									:disabled-date="disabledBeforeTodayAndAfterAWeek"
									placeholder="Filter by date"
									></v-datepicker>
							</div>

							<div class="d-flex justify-content-end align-items-end">
								<button
									:class="
										!isFormValid || processing ? 'btn-secondary' : 'btn-primary'
									"
									:disabled="!isFormValid || processing"
									type="submit"
									class="btn mt-4"
									>
									{{ processing ? 'Creating carousel...' : `Add Carousel` }}
								</button>
							</div>
						</form>
					</b-card>
				</div>

				<div class="col-12 col-xl-6">
					<b-card v-if="uploadingCarouselToS3 === true">
						<div
							class="d-flex flex-column justify-content-center align-items-center"
							>
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2">
								<strong class="text-muted">Uploading carousels...</strong>
							</p>
						</div>
					</b-card>
					<div
						v-if="images.length && uploadingCarouselToS3 === false"
						class="card scroll"
						>
						<div
							class="card-header d-flex justify-content-between align-items-center"
							>
							<h4 class="card-header-title">Carousel Images</h4>
							<button
								@click="removeImages"
								type="submit"
								class="btn text-danger border-danger"
								>
								Remove all image
							</button>
						</div>
						<div class="card-body wrapper">
							<div id="preview" v-for="(image, index) in images" :key="index">
								<img alt="imagePreview" class="imageDimensions" :src="image" />
							</div>
						</div>
					</div>

					<div v-if="!images.length && uploadingCarouselToS3 === false">
						<b-card>
							<div
								class="d-flex justify-content-center align-items-center flex-column"
								>
								<img src="@/assets/img/imagePreviewEmptyState.svg" alt="" />
								<h3>No images to preview</h3>
								<p class="pt-0 mt-0">Start uploading images</p>
							</div>
						</b-card>
					</div>
				</div>
			</div>
		</div>

		<b-modal
			no-close-on-esc
			no-close-on-backdrop
			id="carouselSuccessModal"
			hide-header-close
			hide-footer
			hide-header
			centered
			>
			<div
				class="d-flex justify-content-between align-items-center flex-column"
				>
				<img src="@/assets/img/successIcon.svg" alt="" />
				<h2 class="pt-4">New carousels added</h2>
				<p class="text-muted pb-0 mb-0" style="font-weight: lighter">
					You have successfully added new carousels,
				</p>
				<p class="text-muted" style="font-weight: lighter">
					Kindly enable it to be visible.
				</p>
			</div>

			<button
				@click="hideSuccessCarousel"
				type="submit"
				class="btn btn-primary mt-4 w-100"
				>
				Dismiss
			</button>
		</b-modal>
	</div>
</template>

<script>
import routeResource from '@/api/route'
export default {
  name: 'ManageCarousel',
  data () {
    return {
      processing: false,
      items: [
        {
          text: 'Promotional carousel',
          to: { name: 'CarouselConfig' },
          active: false,
          exact: true
        },
        {
          text: 'Create new carousel',
          href: '#',
          active: true,
          exact: true
        }
      ],
      height: null,
      show_size_warning_alert: false,
      uploadingCarouselToS3: false,
      width: null,
      CTAOptions: [
        'Embed link',
        'Link to carousel',
        'Link to route',
        'Link to discovery page'
      ],
      routes: [],
      images: [],
      imageUrls: [],
      dynamic_urls: [],
      selectedDynamicUrls: [],
      disabledDates: {
        to: new Date(5)
      },
      selected: '',
      loadingDynamicUrls: false,
      form: {
        previewUrl: '',
        image_url: '',
        title: '',
        end_date: '',
        imageUrls: []
      }
    }
  },
  created () {
    this.fetchRoutes()
    this.fetchDynamicUrls()
  },
  methods: {
    disabledBeforeTodayAndAfterAWeek (date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      return date < today
    },
    handleSelectedDynamicUrl (selectedUrl) {
      this.selectedDynamicUrls.push(selectedUrl)
    },
    fetchDynamicUrls () {
      this.loadingDynamicUrls = true
      this.axios
        .get('/v1/dynamic-dashboard/dynamic-urls')
        .then((res) => {
          this.dynamic_urls = res.data.data
        })
        .catch(() => {
          this.$toastr.e(
            'Something weent wrong while fetching dynamic urls',
            'Error'
          )
        })
        .finally(() => {
          this.loadingDynamicUrls = false
        })
    },
    resetForm () {
      return (
        (this.form.previewUrl = ''),
        (this.form.image_url = ''),
        (this.images = []),
        (this.selectedDynamicUrls = []),
        (this.form.imageUrls = []),
        (this.form.title = ''),
        (this.form.end_date = '')
      )
    },
    handleCarousels () {
      if (!this.isFormValid) {
        return
      }
      this.processing = true
      const payload = {
        name: this.form.title,
        assets: this.form.imageUrls,
        end_date: this.form.end_date,
        dynamic_urls: this.selectedDynamicUrls
      }

      this.axios
        .post('/v1/dynamic-dashboard/carousels', payload)
        .then(() => {
          this.resetForm()
          this.showSuccessCarousel()
        })
        .catch((error) => {
          this.$toastr.e(error.message, 'Error')
        })
        .finally(() => {
          this.processing = false
        })
    },
    removeImages () {
      this.images = []
    },
    fetchRoutes () {
      // TODO: raise issue with backend engineer on field added except by specifying limit
      routeResource
        .listRoute({
          params: { status: 1, limit: 1000000 }
        })
        .then((res) => {
          this.routes = res
        })
        .catch((error) => {
          this.$toastr.e(error.message, 'Error')
        })
    },

    handleUpload (e) {
      this.$refs.uploadRef.click()
    },
    showSuccessCarousel () {
      this.$bvModal.show('carouselSuccessModal')
      this.$router.push({
        name: 'CarouselConfig'
      })
    },
    hideSuccessCarousel () {
      this.$bvModal.hide('carouselSuccessModal')
    },
    onFileSelected (e) {
      const input = this.$refs.uploadRef
      const files = input.files

      for (const file of files) {
        const image = new Image()
        const fileReader = new FileReader()
        fileReader.onload = (e) => {
          image.src = fileReader.result

          image.onload = () => {
            if (image.height !== 2480 && image.width !== 1146) {
              this.show_size_warning_alert = true
            } else {
              this.uploadTos3andGetDocumentUrl(file).then((res) => {
                this.form.imageUrls.push(res)
                this.show_size_warning_alert = false
                this.images.push(e.target.result)
              })
            }
          }
        }
        fileReader.readAsDataURL(file)
      }
    },
    async uploadTos3andGetDocumentUrl (file) {
      this.uploadingCarouselToS3 = true
      try {
        const formData = new FormData()
        formData.append('file', file)
        const response = await this.$axios.post(
          '/v1/upload/identity/files',
          formData
        )
        if (response.data?.files?.length) {
          this.uploadingCarouselToS3 = false
          return response.data.files[0].Location
        }
      } catch (error) {
        this.$toastr.e(
          'Something weent wrong while uploading carousel.',
          'Error'
        )
        this.form.previewUrl = ''
        this.uploadingCarouselToS3 = false
      }
    }
  },
  computed: {
    isFormValid () {
      return !!(this.form?.title &&
        this.images?.length &&
        this.form?.end_date &&
        !this.uploadingBannerToS3)
    }
  }
}
</script>

<style scoped>
.ctaUrlParent {
  position: relative;
}

.ctaUrlChild {
  position: absolute;
  top: 42px;
  right: 10px;
  color: gray;
  font-size: 20px;
}

.custom-file-upload {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;
}

.uploadText {
  cursor: pointer;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 6px;
}

.imageDimensions {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.scroll {
  overflow: auto;
  white-space: nowrap;
}
</style>
